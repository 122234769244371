import React, { useState } from 'react'
import classNames from 'classnames';
import './HomepageFAQ.module.scss'
import { FAQ_ITEMS } from '../../../shared/constants';

const HomepageFAQ = () => {

  return (
    <div className='faq-wrapper'>
      <div className='faq-container'>
        <h2 className='faq-title'>Pertanyaan yang Sering Ditanyakan</h2>
        <div className='faq-list'>
          <FAQList
            items={FAQ_ITEMS}
          />
        </div>
      </div>
    </div>
  );
}

const FAQList = (props) => {
  const { items } = props;

  return (
    <>
      {
        items.map((item, index) =>
          <FAQItem
            key={`faq-item-${index}`}
            title={item.title}
            content={item.content}
          />)
      }
    </>
  );
}

const FAQItem = (props) => {
  const { title, content } = props;

  return (
    <div
      className={classNames('faq-item')}
    >
      <div
        className='faq-item-header'>
        <span className='faq-item-label'>{title}</span>
      </div>
      {content()}
    </div>
  );
}

export default HomepageFAQ;